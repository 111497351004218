<script setup>
// eslint-disable-next-line
import { defineEmits, ref } from "vue";

// eslint-disable-next-line
import MapComponent from "./MapComponent.vue";
import TableComponent from "./TableComponent.vue";

// eslint-disable-next-line
const props = defineProps(["results", "searchParams", "center"]);
// eslint-disable-next-line
const emit = defineEmits(["setSearchParamsAndSearch"]);

function setSearchParamsAndSearch(searchParams_new) {
  console.log("setSearchParamsAndSearch()");
  emit("setSearchParamsAndSearch", searchParams_new);
}
</script>
<template>
  <MapComponent
    :results="props.results"
    :center="props.center"
    :searchParams="props.searchParams"
    @setSearchParamsAndSearch="setSearchParamsAndSearch"
  />
  <TableComponent :results="props.results" />
</template>
<style scoped></style>
