import { createApp } from 'vue'
import App from './App.vue'
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports';
import { Auth } from "aws-amplify";
import "jquery-tablesort"

// import config from './aws-exports'

// // configure logger
// const loggerPrefix = "techlab";
// const appName = "apartment_stations";
// const streamName = "ApartmentStations";



const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

console.log(redirectUrl)


Amplify.configure({
    ...awsconfig,
    Auth: {
        // identityPoolId: import.meta.env.VUE_APP_IDENTITY_POOL_ID,
        identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
        region: "ap-northeast-1",
        // userPoolId: import.meta.env.VUE_APP_USER_POOL_ID,
        userPoolId: process.env.VUE_APP_USER_POOL_ID,
        // userPoolWebClientId: import.meta.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
        userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
        cookieStorage: {
            // 環境変数の名前は適宜変更する
            // domain: process.env.VUE_APP_CUSTOM_DOMAIN,
            // domain: `.${import.meta.env.VITE_CUSTOM_DOMAIN}`,
            // domain: `.${import.meta.env.VUE_APP_CUSTOM_DOMAIN}`,
            // domain: `.${process.env.VUE_APP_CUSTOM_DOMAIN}`,
            domain: process.env.VUE_APP_CUSTOM_DOMAIN,
        },
        oauth: {
            // domain: import.meta.env.VUE_APP_OAUTH_DOMAIN,
            domain: process.env.VUE_APP_OAUTH_DOMAIN,
            scope: [
                "phone",
                "email",
                "openid",
                "profile",
                "aws.cognito.signin.user.admin",
            ],
            redirectSignIn: redirectUrl,
            redirectSignOut: redirectUrl,
            responseType: "code",
        },
    },
    //   Logging: {
    //     logGroupName: `/${loggerPrefix}/${appName}/${process.env.NODE_ENV}`,
    //     logStreamName: streamName,
    //   },
    API: {
        endpoints: [
            {
                name: "amplifyAPI",
                // endpoint: import.meta.env.VUE_APP_API_ENDPOINT,
                endpoint: process.env.VUE_APP_API_ENDPOINT,
                custom_header: async () => {
                    return {
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getAccessToken()
                            .getJwtToken()}`,
                    };
                },
            },
        ],
    },
});

// // ローカルでもサーバーでも動くようにredirectURLを自前で制御する必要がある
// var redirectURLs = config.oauth.redirectSignIn.split(",")
// for (var i = 0; i < redirectURLs.length; i++) {
//     if (location.href.indexOf(redirectURLs[i]) >= 0) {
//         config.oauth.redirectSignIn = redirectURLs[i];
//         config.oauth.redirectSignOut = redirectURLs[i];
//         console.log(redirectURLs[i])
//     }
// }
// Amplify.configure(config)

// eslint-disable-next-line
const vueapp = createApp(App).mount('#app')