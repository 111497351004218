<script setup>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
const CUSTOM_DOMAIN = process.env.VUE_APP_CUSTOM_DOMAIN;
</script>

<template>
  <div id="app" class="application_body">
    <!-- <authenticator :hide-sign-up="true" :services="services"> -->
    <!-- <authenticator>
      <ApartmentStations />
    </authenticator> -->
    <!-- <authenticator
      :login-mechanisms="['email']"
      :social-providers="['google']"
      :hide-sign-up="true"
      ><div id="signOutButtonDiv">
        <button @click="signOut" id="signoutbutton">ログアウト</button>
      </div>
      <ApartmentStations />
      <template v-slot:footer>
        <div style="padding: var(--amplify-space-large); text-align: center">
          <p class="amplify-text" style="color: whitesmoke">
            GA テクノロジーズ 2023
          </p>
        </div>
      </template>
    </authenticator> -->
    <authenticator
      :login-mechanisms="['email']"
      :social-providers="['google']"
      :hide-sign-up="true"
    >
      <!-- <template v-slot:header>
        <div style="padding: var(--amplify-space-large); text-align: center">
          <span class="titlespan">アクセスアプリ</span>
        </div>
      </template> -->
      <!-- <template v-slot:sign-in-header>
        <h3
          class="amplify-heading"
          style="
            padding: var(--amplify-space-xl) var(--amplify-space-xl) 0
              var(--amplify-space-xl);
          "
        >
          グーグルアカウントでサインインが出来ます
        </h3>
      </template> -->
      <template v-slot="{ signOut }">
        <div id="signOutButtonDiv">
          <button @click="signOut" id="signoutbutton">ログアウト</button>
        </div>
        <ApartmentStations />
      </template>
      <template v-slot:footer>
        <div style="padding: var(--amplify-space-large); text-align: center">
          <p
            class="amplify-text"
            style="color: var(--amplify-colors-neutral-80)"
          >
            ログインすると、
            <a
              :href="`https://${CUSTOM_DOMAIN}/term_of_service.pdf`"
              target="_blank"
              rel="noreferrer"
            >
              利用規約
            </a>
            に同意したものとします
          </p>
          <p
            class="amplify-text"
            style="color: var(--amplify-colors-neutral-80)"
          >
            &copy; GA technologies AI Strategy Center. All Rights Reserved
          </p>
        </div>
      </template>
    </authenticator>
  </div>
</template>
<script>
import ApartmentStations from "./components/ApartmentStations.vue";

export default {
  name: "App",
  components: {
    ApartmentStations,
  },
  methods: {},
};
</script>

<style>
[data-amplify-authenticator] [data-amplify-router] fieldset {
  display: none;
}
[data-amplify-authenticator] .amplify-divider {
  display: none;
}
[data-amplify-authenticator] form div:not(.federated-sign-in-container) button {
  display: none;
}
[data-amplify-authenticator] form + [data-amplify-footer] {
  display: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  margin: auto;
  /* max-width: 50%; */
}

#signOutButtonDiv {
  display: none;
  position: fixed;
  z-index: 99998;
  justify-content: end;
  width: 100%;
}

#signoutbutton {
  margin-top: 1.5%;
  margin-right: 1.5%;
}

.titlespan {
  font-weight: bolder;
  color: skyblue;
  font-size: -webkit-xxx-large;
  -webkit-text-stroke: 2px black;
}

.amplify-heading {
  font-size: large;
  font-weight: 500;
}

/* prevent some parts of the authenticator from showing */

#app
  > div
  > div
  > div
  > div
  > div
  > form
  > div.amplify-flex.federated-sign-in-container.amplify-authenticator__column.amplify-authenticator__sign-in-base
  > hr {
  display: none;
}

#app > div > div > div > div > div > form > div:nth-child(2) {
  display: none;
}

#app > div > div > div > div > footer > div > button {
  display: none;
}

#app > div > div > div > div > footer > div {
  display: none;
}
</style>
