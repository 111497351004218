<script setup>
import { Authenticator } from "@aws-amplify/ui-vue";

// eslint-disable-next-line
import { Amplify, Auth, API, AWSCloudWatchProvider, Logger } from "aws-amplify";
import { onMounted, ref } from "vue";
import toastr from "toastr";
import ToolDivComponent from "./ToolDivComponent.vue";
import DisplayComponent from "./DisplayComponent.vue";

// eslint-disable-next-line

// // configure logger
// const loggerPrefix = "techlab";
// const appName = "apartment_stations";
// const streamName = "ApartmentStations";

// Amplify.configure({
//   // Logging: {
//   //   logGroupName: `/${loggerPrefix}/${appName}/${process.env.NODE_ENV}`,
//   //   logStreamName: streamName,
//   // },
//   API: {
//     endpoints: [
//       {
//         name: "amplifyAPI",
//         endpoint:
//           "https://jyjsc6krr2.execute-api.ap-northeast-1.amazonaws.com/default/apartment-stations-lambda",
//       },
//     ],
//   },
// });

// const logger = new Logger(loggerPrefix, "INFO");
// Amplify.register(logger);
// logger.addPluggable(new AWSCloudWatchProvider());

onMounted(async () => {
  // const user = await Auth.currentAuthenticatedUser().catch((error) =>
  //   console.log(error)
  // );
  await Auth.currentAuthenticatedUser().catch((error) => console.log(error));
  // const username = user["attributes"]["email"];
  // logger.info(username, "login");
});

// // eslint-disable-next-line
// const items = ref([])
// // eslint-disable-next-line
// function setItems(items_current) {
//     console.log(items_current)
// }

const searchParams = ref({
  // address: "東京都港区六本木3-2-1",
  address: "",
  lat: 35.664617487642616,
  lon: 139.73777355652638,
  companies: true,
  universities: true,
  stations: true,
  special_workplaces: true,
  cutoff: 30,
});
const results = ref({});
const center = ref([35.66481895127626, 139.73682928828117]);

function setSearchParamsAndSearch(searchParams_new) {
  searchParams.value = searchParams_new;
  search();
}

async function search() {
  let apiLoadingToast = toastr.info(
    `検索を実行中: <br>${searchParams.value["address"]}`,
    "",
    {
      timeOut: 0,
      extendedTimeOut: 0,
      preventDuplicates: true,
      disableTimeOut: true,
    }
  );
  console.log("search()");
  // const user = await Auth.currentAuthenticatedUser().catch((error) =>
  //   console.log(error)
  // );
  // const username = user["attributes"]["email"];
  // const token = user.signInUserSession.idToken.jwtToken;

  // searchParams.value["address"] = document.getElementById("address").value;
  // searchParams.value["lat"] = null;
  // searchParams.value["lon"] = null;

  // logger.info(username, `search address: ${searchParams.value["address"]}`);
  // toastr.options.progressBar = true;

  // toastr.options.progressBar = false;

  const myInit = {
    // headers: {
    //   Authorization: token,
    // },
    body: {
      mode: "data",
      params: searchParams.value,
    },
  };
  await API.post("amplifyAPI", "", myInit)
    .then((response) => {
      results.value = response;
      // eslint-disable-next-line
      Object.entries(response.stations).forEach(([_, station]) => {
        if (station.name == "物件") {
          // console.log(`set center value to ${station.lat} ${station.lon}`);
          center.value = [station.lat, station.lon];
        }
      });

      //   addStationsFromResponse(window.map, window.markers, 0, response)
      toastr.success(`完了しました！`);
    })
    .catch((error) => {
      if ("response" in error) {
        if ("data" in error.response) {
          if ("text" in error.response.data) {
            toastr.clear();
            toastr.error(`${error.response.data.text}`);
          } else {
            toastr.clear();
            toastr.error(`検索が失敗しました。<br>もう一度お試しください。`);
          }
        } else {
          toastr.clear();
          toastr.error(`検索が失敗しました。<br>もう一度お試しください。`);
        }
      } else {
        toastr.clear();
        toastr.error(`検索が失敗しました。<br>もう一度お試しください。`);
      }
    })
    .finally(() => {
      apiLoadingToast.fadeOut();
    });
}

// function sampleClickSearch() {
//     console.log('sampleClickSearch()')
//     searchAddress()
// }
</script>

<template>
  <authenticator>
    <template v-slot="{ signOut }">
      <div class="centered_flex" id="logout_button_div">
        <button @click="signOut" id="signoutbutton">ログアウト</button>
      </div>
      <div class="centered_flex">
        <!-- <button @click="sampleClickSearch">sampleClickSearch</button> -->
        <DisplayComponent
          :searchParams="searchParams"
          @setSearchParamsAndSearch="setSearchParamsAndSearch"
          :results="results"
          :center="center"
        >
        </DisplayComponent>
      </div>
      <!-- <div class="centered_flex"> -->
      <div>
        <ToolDivComponent
          :searchParams="searchParams"
          @setSearchParamsAndSearch="setSearchParamsAndSearch"
        />
      </div>
    </template>
  </authenticator>
</template>

<style scoped>
#companies_table {
  /* max-width: 2368px; */
  /* max-width: 40%; */
  font-size: 10pt;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  flex: 50%;
  /* max-width: 30%; */
}

#universities_table {
  /* max-width: 2368px; */
  /* max-width: 40%; */
  font-size: 10pt;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  flex: 50%;
  /* max-width: 30%; */
}

#special_workplaces_table {
  /* max-width: 2368px; */
  /* max-width: 40%; */
  font-size: 10pt;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  flex: 50%;
  /* max-width: 30%; */
}

#access_div {
  display: grid;
  justify-content: center;
  white-space: pre;
}

.centered_flex {
  display: flex;
  justify-content: center;
  white-space: pre;
  font-size: large;
  align-items: center;
  flex-wrap: wrap;
}

#logout_button_div {
  margin: 20px;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 20px;
  justify-content: left;
}

#download_button_div {
  margin: 20px;
  margin-top: 1px;
  margin-bottom: 1px;
}

#loading_spiral {
  height: 10%;
  aspect-ratio: 1;
}

#loading_spiral_container {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#overlay {
  position: fixed;
  /* Sit on top of the page content */
  /* Full width (cover the whole page) */
  width: 100%;
  /* Full height (cover the whole page) */
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  /* Black background with opacity */
  z-index: 99999;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

.hidden {
  display: none;
}
</style>
