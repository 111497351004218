<script setup>
import { defineEmits, ref } from "vue";

// eslint-disable-next-line
const props = defineProps(["searchParams"]);
// eslint-disable-next-line
const emit = defineEmits(["setSearchParamsAndSearch"]);

function submitLocalSearchParams() {
  console.log("submitLocalSearchParams()");
  emit("setSearchParamsAndSearch", localSearchParams.value);
}

const localSearchParams = ref(props.searchParams);
</script>
<template>
  <div class="newToolGrid">
    <div class="verticalGrid">
      <div class="topRow"></div>
      <div class="paramsCheckboxGrid">
        <!-- <div class="request_parameter hidden">
        <input
          class="checkbox"
          type="checkbox"
          id="stations"
          v-model="localSearchParams.stations"
        />
        <label for="stations">最寄り駅</label>
      </div> -->
        <div>
          <input
            class="checkbox"
            type="checkbox"
            id="companies"
            v-model="localSearchParams.companies"
          />
          <label for="companies">企業</label>
        </div>
        <div>
          <input
            class="checkbox"
            type="checkbox"
            id="universities"
            v-model="localSearchParams.universities"
          />
          <label for="universities">大学</label>
        </div>
        <div>
          <input
            class="checkbox"
            type="checkbox"
            id="special_workplaces"
            v-model="localSearchParams.special_workplaces"
          />
          <label for="special_workplaces">大規模オフィスビル</label>
        </div>
      </div>
      <div></div>
    </div>

    <div>
      <div class="verticalGrid">
        <div class="topRow"></div>
        <div>
          <label for="cutoff">最大アクセス時間 </label>
          <input
            class="field"
            type="number"
            id="cutoff"
            v-model="localSearchParams.cutoff"
          />分
        </div>
        <div></div>
      </div>
    </div>
    <div class="verticalGrid">
      <div class="topRow flexCenterOnly">
        <span class="bottomFlex greyText">利用範囲は国道16号線内</span>
      </div>
      <div id="address_input_div">
        <div>
          <input
            size="30"
            id="address"
            v-model="localSearchParams.address"
            :placeholder="'住所を入力（例：東京都港区六本木3-2-1）'"
          />
          <button @click="submitLocalSearchParams">住所で検索</button>
        </div>
      </div>
      <div></div>
    </div>
  </div>
  <!-- <hr class="roundedDivider" /> -->
  <div id="request_params_row1"></div>
  <div id="request_params_row2"></div>
</template>
<style scoped>
#address {
  width: 30vw;
}

hr.roundedDivider {
  border-top: 8px solid #bbb;
  border-radius: 5px;
}

div.newToolGrid {
  display: grid;
  grid-template-columns: 100fr 80fr 130fr;
  font-size: larger;
}

div.paramsCheckboxGrid {
  display: grid;
  grid-template-columns: 3fr 3fr 8fr;
}

div.verticalGrid {
  display: grid;
  /* grid-template-columns: 3fr 3fr 8fr; */
  grid-template-rows: 1fr 4fr 1fr;
}

div.topRow {
  height: 30px;
}

.flexCenterOnly {
  display: flex;
  justify-content: center;
}

.greyText {
  color: gray;
}

.bottomFlex {
  align-self: flex-end;
}

#request_params_row1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

#request_params_row2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.request_parameter {
  margin: 20px;
  font-size: larger;
}

.hidden {
  display: none;
}

input.checkbox {
  transform: scale(2);
  margin-right: 10px;
}

input.field {
  width: 100px;
  margin-right: 5px;
}

#address_input_div {
  margin: 20px;
  margin-top: 1px;
  margin-bottom: 1px;
}
</style>
